<template>
	<div ref="threeCanvas" class="three-canvas" style="width: 100vw;height: 100vh;margin: auto;"></div>
</template>

<script type="module">
	import * as THREE from 'three';
	import {
		OrbitControls
	} from 'three/examples/jsm/controls/OrbitControls.js';
	import {
		GLTFLoader
	} from 'three/examples/jsm/loaders/GLTFLoader.js';
	// import {
	// 	TDSLoader
	// } from 'three/examples/jsm/loaders/TDSLoader.js';
	// import {
	// 	OBJLoader
	// } from 'three/examples/jsm/loaders/OBJLoader.js';
	import {
		CSS3DRenderer,
		// CSS3DObject,
		CSS3DSprite
	} from 'three/examples/jsm/renderers/CSS3DRenderer.js';
	import axios from 'axios';

	export default {
		name: 'ThreeJSComponent',
		data() {
			return {
				scene: null,
				camera: null,
				model: null,
				mouse: null,
				intersectedObject: null,
				raycaster: null,
				labelRenderer: null,
				labels: [],
				selectedObject: null,
				oldColor: null,
				selectedObjectList: [],
				token: null,
				projectId: null,
				data: null,
				spriteList:[],
			};
		},
		mounted() {
			const params = new URLSearchParams(window.location.search);
			this.token = params.get('token'); // 获取 param1 的值
			this.projectId = params.get('projectId'); // 获取 param2 的值
			this.initThreeJS();

		},
		methods: {
			initThreeJS() {
				//定义全局变量
				this.mouse = new THREE.Vector2();
				this.raycaster = new THREE.Raycaster();
				this.intersectedObject = null;

				const self = this;

				const width = this.$refs.threeCanvas.clientWidth;
				const height = this.$refs.threeCanvas.clientHeight; 

				// 设置世界中心
				const worldFocus = new THREE.Vector3(0, 0, 0);

				// 创建场景  
				this.scene = new THREE.Scene();
				this.scene.background = new THREE.Color("#8D8D8D");
				
				// 创建WebGL渲染器  
				const renderer = new THREE.WebGLRenderer({
					antialias: true
				});
				renderer.setSize(width, height);
				this.$refs.threeCanvas.appendChild(renderer.domElement);

				// 创建CSS3DRenderer渲染器  
				const labelRenderer = new CSS3DRenderer();
				labelRenderer.setSize(width, height); // 设置渲染器尺寸
				labelRenderer.domElement.style.position = 'absolute'; // 设置渲染器样式
				labelRenderer.domElement.style.top = '0'; // 设置渲染器样式
				labelRenderer.domElement.style.pointerEvents = 'none';
				this.$refs.threeCanvas.appendChild(labelRenderer.domElement); // 将渲染器挂载到页面上

				// 创建相机  
				this.camera = new THREE.PerspectiveCamera(50, width / height, 0.1, 3000);
				this.camera.position.set(0, 800, 0);
				this.camera.lookAt(worldFocus);

				// 创建光源（这里以点光源为例）  
				const light1 = new THREE.HemisphereLight(0xffffff, 0x444444); // 半球光（天空颜色，地面颜色，光照强度）
				light1.position.set(0, 20, 0);
				this.scene.add(light1);
				const light2 = new THREE.DirectionalLight(0xffffff); // 平行光（颜色， 光照强度）
				light2.position.set(0, 0, 20);
				this.scene.add(light2);

				// 创建控制器
				const controls = new OrbitControls(this.camera, renderer.domElement);
				controls.target.set(worldFocus.x, worldFocus.y, worldFocus.z); // 摄像机围绕旋转的中心
				controls.enablePan = false; // 禁止摄像机平移
				controls.enableDamping = true; // 设置阻尼，需要在 update 调用
				controls.dampingFactor = 0.09;

				// 创建射线碰撞检测器
				const raycaster = new THREE.Raycaster();
				const mouse = new THREE.Vector2();

				// 监听鼠标点击事件 
				document.addEventListener('click', (event) => {

					// 将鼠标点击位置的屏幕坐标转换成Three.js中的标准坐标 --------------------------------------------------
					mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
					mouse.y = -(event.clientY / window.innerHeight) * 2 + 1; // 注意Y轴的转换，确保在-1到1之间  

					// 通过鼠标点的位置和当前相机的矩阵计算出射线
					raycaster.setFromCamera(mouse, self.camera);

					// 获取射线与所有模型子节点相交的数组集合
					const intersects = raycaster.intersectObjects(self.scene.children,
						true); // 第二个参数为true，表示递归检查所有子对象
					// 遍历相交结果，改变被点击子节点的颜色
					if (intersects.length > 0) {
						const intersectedObject = intersects[0].object;
						if (self.selectedObject != null && self.oldColor != null) {
							self.selectedObject.material.color.set(self.oldColor);
						}
						if (self.selectedObjectList.length > 0) {
							for (let index in self.selectedObjectList) {
								self.selectedObjectList[index].material.color.set(self.oldColor);
							}
							self.selectedObjectList = []
						}
						if(self.spriteList.length > 0){
							for(let index in self.spriteList){
								self.scene.remove(self.spriteList[index]);
							}
							self.spriteList = []
						}
						if (intersectedObject.name == 'bottom' || intersectedObject.name.indexOf('referTo') >= 0) {
							return
						}
						if (!Array.isArray(intersectedObject.material)) {
							// intersectedObject.material.color.set(0xff0000); // 设置为红色  
							// 克隆材质以避免影响其他对象  
							let newMaterial = intersectedObject.material.clone();

							self.oldColor = intersectedObject.material.color;

							newMaterial.color.set(0xff0000); // 设置为红色  

							// 应用新材质到被点击的子对象上  
							intersectedObject.material = newMaterial;
							self.selectedObject = intersectedObject;
						}
						self.model.traverse(function(child) {
							let name = intersects[0].object.name
							if(intersects[0].object.name.indexOf('#') >= 0){
								name = intersects[0].object.name.substring(0,intersects[0].object.name.indexOf('#')+1)
							}
							
							let arrX = child.name.substring(child.name.indexOf('(')+1,child.name.indexOf(')')).split(',')
							
							if (arrX.includes(name)) {
								let newMaterial = child.material.clone();
								newMaterial.color.set(0xff0000); // 设置为红色  
								child.material = newMaterial;
								self.selectedObjectList.push(child)
							}
							if (child.isMesh) {
								let index = child.name.indexOf('(')
								let str = child.name
								if (index >= 0) {
									str = child.name.substring(0, index)
								}
								let arrR = child.name.substring(child.name.indexOf('(')+1,child.name.indexOf(')')).split(',')
								if (arrR.includes(name)) {
									const boundingBox = new THREE.Box3().setFromObject(child);
									const labelPosition = new THREE.Vector3(
										(boundingBox.max.x + boundingBox.min.x) / 2,
										boundingBox.max.y + 0.1,
										(boundingBox.max.z + boundingBox.min.z) / 2
									);
							
									const div = document.createElement('div');
									if (child.name.indexOf(name) >= 0) {
										div.innerHTML =
											'<p style="font-size:12px; font-family: Arial;color:#FFF;padding:3px;">' +
											str + '</p>';
											
										const sprite = new CSS3DSprite(div);
										sprite.position.copy(labelPosition);
										self.spriteList.push(sprite);
										
										self.scene.add(sprite);
							
									}
							
								}
							
							}
						})
					}
				});

				// 创建 glb 加载器
				// const loader = new TDSLoader();
				const loader = new GLTFLoader();
				// const loader = new OBJLoader();

				// 异步加载模型
				axios.get('https://xft.csxuanfang.com/prod-excessive/japi/projectModel/querywithPage', {
						headers: {
							'Authorization': 'Bearer ' + this.token
						},
						params: {
							'projectId': this.projectId,
							'currentPage': 1,
							'pageSize': 10
						}
					})
					.then(response => {
						// 请求成功时处理响应数据
						this.data = response.data.data;
						// this.data[0].model
						document.title = this.data[0].projectName;
						loader.load(this.data[0].model, (e) => {
							this.model = e.scene;
							this.model.traverse(function(child) {
								if (child.isMesh) {
									// const position = child.position;
									let index = child.name.indexOf('(')
									let str = child.name
									if (index >= 0) {
										str = child.name.substring(0, index)
										// let st = child.name.substring(index+1,child.name.indexOf(')'))
									}
									if (str.indexOf('#') > 0 || /^\d+m$/.test(str)) {
										const boundingBox = new THREE.Box3().setFromObject(child);
										const labelPosition = new THREE.Vector3(
											(boundingBox.max.x + boundingBox.min.x) / 2 / 10,
											(boundingBox.max.y + 0.1) / 10,
											(boundingBox.max.z + boundingBox.min.z) / 2 / 10
										);

										const div = document.createElement('div');
										if (str.indexOf('#') > 0) {
											str = str.replaceAll('_',' ')
											div.innerHTML =
												'<p style="font-size:12px; font-family: Arial;background-color:#FFF;border-radius:25px;border:1px solid #00a7a7;color:#00a7a7;padding:1px 4px;">' +
												str + '</p>';

										}

										const sprite = new CSS3DSprite(div);
										sprite.position.copy(labelPosition);

										self.scene.add(sprite);

									}

								}

							});

							this.model.position.set(-10, -10, 0); // 设置模型位置
							this.model.scale.set(0.1, 0.1, 0.1); // 设置模型大小
							this.scene.add(this.model);

							// 模型加载到场景后，开启渲染
							render();
						});

						// 渲染（闭包）
						var render = () => {
							requestAnimationFrame(render);

							//光源跟随相机
							var vector = this.camera.position.clone();
							light2.position.set(vector.x, vector.y, vector.z);

							// 控制器
							if (controls.enabled) controls.update();

							// 更新标签的旋转
							this.updateLabelRotation();

							labelRenderer.render(this.scene, this.camera);
							// 渲染场景
							renderer.render(this.scene, this.camera);
						};
					})
					.catch(error => {
						// 请求失败时处理错误
						console.error('Error fetching data:', error);
					});

			},
			updateLabelRotation() {
				this.labels.forEach((label) => {
					const cameraEuler = new THREE.Euler().setFromQuaternion(this.camera.quaternion,
						'YXZ'); // 注意欧拉角的顺序  
					const yaw = cameraEuler.y;
					label.rotation.y = -yaw;
				});
			},
			getBuildingHeight() {
				this.model.traverse(function(child) {
					if (child.isMesh) {
						console.log(child.geometry)
					}
				})
			},
			handleClick(event) {
				// 更新鼠标位置
				this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
				this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
				// 检查是否需要更新射线投射和相交对象 
				this.checkIntersections();
			},
			checkIntersections() {
				// 更新射线投射  
				this.raycaster.setFromCamera(this.mouse, this.camera);
				const intersects = this.raycaster.intersectObjects(this.scene.children, true);

				if (intersects.length > 0) {
					if (this.intersectedObject !== null) {
						// 恢复原始颜色  
						this.intersectedObject.material.color.copy(this.intersectedObject.originalColor);
					}
					this.intersectedObject = intersects[0].object;
					// 保存原始颜色（如果尚未保存）  
					if (!this.intersectedObject.originalColor) {
						this.intersectedObject.originalColor = this.intersectedObject.material.color.clone();
					}
					this.intersectedObject.material.color.set(0xff0000); // 改变颜色为红色  
				} else {
					if (this.intersectedObject !== null) {
						// 恢复原始颜色  
						this.intersectedObject.material.color.copy(this.intersectedObject.originalColor);
					}
					this.intersectedObject = null;
				}
			}
		},
		beforeDestroy() {
			// 清理资源，如取消动画循环等  
			cancelAnimationFrame(this.animateFrameId); // 假设你有一个animateFrameId来跟踪requestAnimationFrame  
		},
	};
</script>

<style scoped>
	.three-canvas {
		width: 100%;
		height: 100vh;
		/* 或者其他你想要的尺寸 */
		overflow: hidden;
	}
</style>
